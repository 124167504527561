import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userName: localStorage.getItem("username"),
    userEmail: localStorage.getItem("email"),
    isAuthenticated: !!localStorage.getItem("isAuthenticated"),
    Role: localStorage.getItem("role"),
    Token: localStorage.getItem("token"),
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      (state.isAuthenticated = true),
        localStorage.setItem("userName", userData.user.name);
      localStorage.setItem("userEmail", userData.user.email);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("role", userData.user.role);
      localStorage.setItem("token", userData.accessToken);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    },
    LOGOUT(state, userData) {
      state.user = userData;
      (state.isAuthenticated = false),
        localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("role");
      localStorage.removeItem("token");
    },
    UPDATE_USER(state, userData) {
      state.user = userData;
      (state.isAuthenticated = true),
      localStorage.setItem("userName", userData.user.name);
      localStorage.setItem("userEmail", userData.user.email);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("role", userData.user.role);
    },
  },
  actions: {
    login({ commit }, credentials) {
      return axios
        .post("http://test.groupe2is.online/back/api/user/login", credentials)
        .then(({ data }) => {
          if (data)
            commit("SET_USER_DATA", data)
        })
        .catch((e) => {
          console.log(e)
        });
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
    updateUser({ commit }, userData) {
      commit("UPDATE_USER", userData);
    },
  },
  modules: {},
  getters: {
    isUserLoggedIn(state) {
      return !!state.isAuthenticated;
    },
  },
});
